*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  max-width: 100vw;
  height: 100%;
  font-size: 16px;
  font-family: sans-serif;
  background-image: url("https://source.unsplash.com/random/1920x1080/?wallpaper,landscape");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: fixed;
}

button {
  cursor: pointer;
}
input{
  cursor :auto;
}
.App {
  width: 100%;
  height: 100vh;
  color: #4c768d;
}

/* navbar */
.nav-bar {
  width: 100vw;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #88dded;
  position: fixed;
  background-color: #00000042;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.nav-bar > h1{
  font-size: 40px;
}
.sign-out {
  padding: 15px 20px;
  border-radius: 5px;
  color: #88dded;
  border: 1px solid #1c2c4c;
  background-color: #1c2c4c;
  font-weight: 900;
}

/* welcome page */
.welcome {
  text-align: center;
  color: #7cc5d9;
}
.welcome>h2{
  font-size: 35px;
}
.welcome :is(h2, p, img) {
  margin-bottom: 20px;
}
.center{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000096;
}
.chat-box{
  background-color: #00000096;
}
/* chat component */
.messages-wrapper {
  padding: 80px 30px 80px 30px;
}
.chat-bubble {
  border-radius: 20px 20px 20px 0;
  padding: 15px;
  background-color: #7cc5d9;
  color: #1c2c4c;
  width: max-content;
  max-width: calc(100% - 50px);
  box-shadow: -2px 2px 1px 1px #4c768d;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.chat-bubble.right {
  margin-left: auto;
  border-radius: 20px 20px 0 20px;
  background-color: #fff;
  box-shadow: -2px 2px 1px 1px #88dded;
}
.chat-bubble__left {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.user-name {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #1c2c4c;
}
.user-message {
  word-break: break-all;
}
.message-time {
  display: block;
  text-align: right;
}

/* send message */
.send-message {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 20px 30px;
  display: flex;
}
.send-message > input {
  height: 40px;
  padding: 10px 10px;
  border-radius: 5px 0 0 5px;
  border: none;
  flex-grow: 1;
  background-color: white;
  color: #1c2c4c;
  font-size: 1rem;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.send-message > input:placeholder {
  color: #ddd;
}
.send-message > :is(input, button):focus {
  outline: none;
  border-bottom: 1px solid #7cc5d9;
}
.send-message > button {
  width: 70px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 0 5px 5px 0;
  color: #242443;
  border: 1px solid #7cc5d9;
  background-color: #7cc5d9;
  font-weight: 600;
}
@media screen and (min-width:0px) and (max-width:500px) {
  .messages-wrapper{
    padding: 80px 5px 80px 5px;
  }
  .nav-bar{
    padding: 10px;
  }
  .nav-bar > h1{
    font-size: 30px;
  }
  .welcome>h2{
    font-size: 25px;
  }
}